<template>
  <div class="manage-dashboard">
    <div class="container-fluid mt--6">
      <div class="row justify-content-md-center mb-xs-3 mb-xl-3">
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <!---->
            <div class="card-body p-4">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    จำนวนลูกค้า
                  </h5>
                  <span class="h2 font-weight-bold mb-0">{{ userActive }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape text-white rounded-circle shadow bg-gradient-green"
                  >
                    <i class="ni ni-circle-08"></i>
                  </div>
                </div>
              </div>
            </div>
            <!----><!---->
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <!---->
            <div class="card-body p-4">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    จำนวนลูกค้าที่ถูกบล็อก
                  </h5>
                  <span class="h2 font-weight-bold mb-0">{{ userBlock }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape text-white rounded-circle shadow bg-gradient-red"
                  >
                    <i class="ni ni-button-power"></i>
                  </div>
                </div>
              </div>
            </div>
            <!----><!---->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card mb-3">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <el-input
                    ref="search"
                    class="form-control-alternative"
                    placeholder="Search enter for Member Code, Name, Username and Email"
                    v-model="search"
                    @input="searchEnter"
                  >
                  </el-input>
                </div>
              </div>
            </div>

            <div class="card-body min-vh">
              <el-table
                :data="tableData"
                border
                :loading="loading"
                style="width: 100%"
              >
                <el-table-column
                  prop="userid"
                  label="ID"
                  width="50"
                  header-align="center"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="plusuid"
                  label="Plus UID"
                  header-align="center"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="membercode"
                  label="Member Code"
                  header-align="center"
                  align="center"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="name"
                  label="Name"
                  header-align="center"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="username"
                  label="User Name"
                  header-align="center"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="email"
                  label="Email"
                  header-align="center"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="banktype"
                  label="Type"
                  header-align="center"
                  align="center"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="registerDate"
                  label="Register Date"
                  header-align="center"
                  width="150"
                ></el-table-column>
                <el-table-column
                  prop="lastvisitDate"
                  label="Last Login"
                  header-align="center"
                  width="150"
                ></el-table-column>
                <el-table-column
                  prop="lastResetTime"
                  label="Last Reset"
                  header-align="center"
                  width="150"
                ></el-table-column>
                <el-table-column
                  prop="role"
                  label="Admin"
                  header-align="center"
                  align="center"
                  width="150"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.role == 'admin'"
                      type="success"
                      class="btn-mini"
                      @click="
                        $router.push(
                          '/manage/users/' +
                            scope.row.userid +
                            '/' +
                            scope.row.plusuid
                        )
                      "
                      >Users</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="userid"
                  label="Update"
                  header-align="center"
                  align="center"
                  width="250"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="warning"
                      class="btn-mini"
                      @click="getUser(scope.row.userid)"
                      >Edit</el-button
                    >
                    <el-button
                      type="primary"
                      class="btn-mini"
                      @click="getAccountStatus(scope.row.plusuid)"
                      >Update Status</el-button
                    >
                    <el-button
                      type="info"
                      class="btn-mini"
                      @click="resetPin(scope.row)"
                      >Reset PIN</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="userid"
                  label="Password"
                  header-align="center"
                  align="center"
                  width="150"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      class="btn-mini"
                      @click="changePassword(scope.row.userid)"
                      >Change</el-button
                    >
                    <el-button
                      type="info"
                      class="btn-mini"
                      @click="resetPassword(scope.row.userid)"
                      >Reset</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="userid"
                  label="Action"
                  header-align="center"
                  align="center"
                  width="80"
                  fixed="right"
                  v-if="$store.getters.loginas"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="danger"
                      class="btn-mini"
                      @click="ghostPage(scope.row.userid)"
                      >สิงร่าง</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="Edit User" :visible.sync="dialogUserUpdate" width="50%">
      <el-form
        label-width="100px"
        ref="formEditUser"
        :model="formEditUser"
        :rules="formEditUserRule"
      >
        <el-form-item label="Name">
          <el-input
            v-model="formEditUser.name"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="User Name" prop="username">
          <el-input
            v-model="formEditUser.username"
            class="form-control-alternative"
          ></el-input>
        </el-form-item>
        <el-form-item label="email" prop="email">
          <el-input
            v-model="formEditUser.email"
            class="form-control-alternative"
          ></el-input>
        </el-form-item>
        <el-form-item label="Role" prop="role">
          <el-select
            v-model="formEditUser.role"
            placeholder="Select role"
            class="form-control-alternative"
          >
            <el-option label="Admin" value="admin"></el-option>
            <el-option label="Individual" value="individual"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogUserUpdate = false">Cancel</el-button>
        <el-button
          type="primary"
          v-loading="submiting"
          :disabled="submiting"
          @click="updateUser()"
          >Submit</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="Update Account" :visible.sync="dialogaccountStatus" width="50%">
      <el-form
        label-width="100px"
        ref="formAccount"
        :model="accountStatus"
      >
        <el-form-item label="Name">
          <el-input
            v-model="accountStatus.name"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="Membercode">
          <el-input
            v-model="accountStatus.membercode"
            readonly
            class="form-control-alternative"
          ></el-input>
        </el-form-item>

        <el-form-item label="Status">
          <el-select
            v-model="accountStatus.accountstatus"
            placeholder="Select status"
            class="form-control-alternative"
          >
            <el-option label="Active" value="active"></el-option>
            <el-option label="Lock" value="lock"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogaccountStatus = false">Cancel</el-button>
        <el-button
          type="primary"
          v-loading="submitingAccount"
          :disabled="submitingAccount"
          @click="updateAccountStatus()"
          >Submit</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Change Password"
      :visible.sync="dialogPasswordChange"
      width="50%"
    >
      <el-form
        label-width="200px"
        ref="formPassword"
        :model="formPassword"
        :rules="formPasswordRule"
      >
        <el-form-item label="Name">
          <el-input
            v-model="formEditUser.name"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="User Name" prop="username">
          <el-input
            v-model="formEditUser.username"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="New Password" prop="new_password">
          <el-input
            type="password"
            v-model="formPassword.new_password"
            class="form-control-alternative"
            show-password
          ></el-input>
          <el-progress
            :percentage="scorePassword(formPassword.new_password)"
            :color="colors"
            :format="formatProgress"
          ></el-progress>
        </el-form-item>
        <el-form-item label="Generate Password">
          <el-input
            v-model="generate_password"
            class="input-with-select"
            readonly="readonly"
          >
            <el-button
              slot="append"
              icon="el-icon-refresh"
              @click="generatePassword"
            ></el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPasswordChange = false">Cancel</el-button>
        <el-button
          type="primary"
          v-loading="submitingPassword"
          @click="updatePassword()"
          >Submit</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Send reset password to customer"
      :visible.sync="dialogPasswordReset"
      width="50%"
    >
      <el-form
        label-width="100px"
        ref="formResetPassword"
        :model="formResetPassword"
      >
        <el-form-item label="Name">
          <el-input
            v-model="formResetPassword.name"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="User Name" prop="username">
          <el-input
            v-model="formResetPassword.username"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="email" prop="email">
          <el-input
            v-model="formResetPassword.email"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPasswordReset = false">Cancel</el-button>
        <el-button
          type="primary"
          v-loading="submitingResetPassword"
          @click="sendResetPassword()"
          >Submit</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Utils from "@/utils";
import ManageApi from "@/api/manage";
import { forgotPassword } from "@/api/user";
export default {
  computed: {
    userActive() {
      return Utils.numberToString(this.dashboardData.userActive);
    },
    userBlock() {
      return Utils.numberToString(this.dashboardData.userBlock);
    },
  },
  data() {
    return {
      search: "",
      loading: false,
      submiting: false,
      submitingAccount: false,
      submitingPassword: false,
      submitingResetPassword: false,
      dialogUserUpdate: false,
      dialogPasswordChange: false,
      dialogaccountStatus: false,
      dialogPasswordReset: false,
      enterTimeout: null,
      tableData: [],
      generate_password: "",
      colors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#5cb87a", percentage: 100 },
      ],
      dashboardData: {
        userActive: 0,
        userBlock: 0,
      },
      formEditUser: {
        id: "",
        name: "",
        username: "",
        email: "",
        role: "",
      },
      formResetPassword: {
        id: "",
        name: "",
        username: "",
        email: "",
      },
      formPassword: {
        new_password: "",
      },
      formEditUserRule: {
        username: [
          {
            required: true,
            message: "Please enter Username",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please enter Email",
            trigger: "blur",
          },
        ],
      },
      formPasswordRule: {
        new_password: [
          {
            required: true,
            message: "Please enter the currenct password",
            trigger: "blur",
          },
        ],
      },
      accountStatus: {
        uid: "",
        membercode: "",
        name: "",
        surname: "",
        accountstatus: 1,
      },
    };
  },
  methods: {
    getDataDashBoard() {
      ManageApi.dashboard().then(({ result, data }) => {
        if (result) {
          this.dashboardData.userActive = data.userActive;
          this.dashboardData.userBlock = data.userBlock;
        }
      });
    },
    searchEnter() {
      if (this.enterTimeout) clearTimeout(this.enterTimeout);
      this.enterTimeout = setTimeout(() => {
        this.searchCustomer();
      }, 800);
    },
    searchCustomer() {
      this.loading = true;
      ManageApi.search(this.search).then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.tableData = data;
        }
      });
    },
    getUser(id) {
      const loading = this.$notifyloading("Loading...");
      ManageApi.getUser(id).then(({ result, data }) => {
        loading.close();
        if (result) {
          this.formEditUser.id = data.id;
          this.formEditUser.name = data.name;
          this.formEditUser.username = data.username;
          this.formEditUser.email = data.email;
          this.formEditUser.role = data.role;
          this.dialogUserUpdate = true;
        }
      });
    },
    updateUser() {
      this.$refs.formEditUser.validate((valid) => {
        if (valid) {
          this.submiting = true;
          const data = {
            username: this.formEditUser.username,
            email: this.formEditUser.email,
            role: this.formEditUser.role,
          };
          ManageApi.updateUser(this.formEditUser.id, data).then(
            ({ result, message }) => {
              this.dialogUserUpdate = false;
              this.submiting = false;
              if (result) {
                this.formEditUser.id = "";
                this.$refs.formEditUser.resetFields();
              } else {
                this.$swal("Message", message, "error");
              }
            }
          );
        }
      });
    },
    generatePassword() {
      var length = 16,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.generate_password = retVal;
    },
    scorePassword(pass) {
      var score = 0;
      if (!pass) return score;

      // award every unique letter until 5 repetitions
      var letters = new Object();
      for (var i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
      }

      // bonus points for mixing it up
      var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
      };

      var variationCount = 0;
      for (var check in variations) {
        variationCount += variations[check] == true ? 1 : 0;
      }
      score += (variationCount - 1) * 10;

      return parseInt(score) > 100 ? 100 : parseInt(score);
    },
    formatProgress(percentage) {
      if (percentage < 60) {
        return "Weak";
      } else if (percentage < 80) {
        return "Strong";
      } else if (percentage >= 80) {
        return "Good";
      }
    },
    changePassword(id) {
      const loading = this.$notifyloading("Loading...");
      ManageApi.getUser(id).then(({ result, data }) => {
        loading.close();
        if (result) {
          this.formEditUser.id = data.id;
          this.formEditUser.name = data.name;
          this.formEditUser.username = data.username;
          this.formEditUser.email = data.email;
          this.dialogPasswordChange = true;
        }
      });
    },
    updatePassword() {
      this.$refs.formPassword.validate((valid) => {
        if (valid) {
          this.submitingPassword = true;
          ManageApi.changePassword(
            this.formEditUser.id,
            this.formPassword
          ).then(({ result, message }) => {
            this.dialogPasswordChange = false;
            this.submiting = false;
            this.submitingPassword = false;

            if (result) {
              this.formEditUser.id = "";
              this.$refs.formPassword.resetFields();
              this.$swal(`Message`, message, "success");
            } else {
              this.$swal("Message", message, "error");
            }
          });
        }
      });
    },
    resetPassword(id) {
      const loading = this.$notifyloading("Loading...");
      ManageApi.getUser(id).then(({ result, data }) => {
        loading.close();
        if (result) {
          this.formResetPassword.id = data.id;
          this.formResetPassword.name = data.name;
          this.formResetPassword.username = data.username;
          this.formResetPassword.email = data.email;
          this.dialogPasswordReset = true;
        }
      });
    },
    sendResetPassword() {
      this.submitingResetPassword = true;
      const formData = {
        email: this.formResetPassword.email,
        forgot_user: true,
        forgot_password: true,
      };
      forgotPassword(formData).then(({ result, message }) => {
        this.dialogPasswordReset = false;
        this.submitingResetPassword = false;

        if (result) {
          this.$refs.formResetPassword.resetFields();
          this.$swal(`Message`, message, "success");
        } else {
          this.$swal("Message", message, "error");
        }
      });
    },
    resetPin(data) {
      this.$swal({
        title: "Reset Passcode",
        html: `This will reset passcode in application of <b>${data.name}</b>. Continue?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Comfirm",
        cancelButtonText: "Cancel",
        showCloseButton: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          ManageApi.resetPasscode({ id: data.userid, resetpin: 1 }).then(
            ({ result, message }) => {
              if (result) {
                this.$swal(`Message`, message, "success");
              } else {
                this.$swal("Message", message, "error");
              }
            }
          );
        } else {
          this.$message(`Remove payee ${data.name} Canceled`);
        }
      });
    },
    ghostPage(id) {
      window.open(`/manage/ghostconnect/${id}`, "_blank");
      //this.$router.push()
    },
    getAccountStatus(plusuid) {
      ManageApi.getAccountStatus(plusuid).then(({ result, data, message }) => {
        if (result) {
          this.accountStatus.uid = data.uid;
          this.accountStatus.name = data.name;
          this.accountStatus.membercode = data.membercode;
          this.accountStatus.accountstatus = data.accountstatus === 1 ? 'active' : "lock";
          this.dialogaccountStatus = true;
        } else {
          this.$swal("Message", message, "error");
        }
      });
    },
    updateAccountStatus() {
      this.submitingAccount = true;
      ManageApi.updateAccountStatus(this.accountStatus.uid, { status: this.accountStatus.accountstatus }).then(({ result, message }) => { 
        this.submitingAccount = false;
        if (result) {
          this.dialogaccountStatus = false;
          this.$swal(`Message`, message, "success");
        } else {
          this.$swal("Message", message, "error");
        }
      })
    },
  },
  created() {
    this.getDataDashBoard();
  },
};
</script>

<style lang="scss">
.manage-dashboard {
  .form-control-alternative {
    border: 1px solid #f3e9e9;
    .el-input__inner::placeholder {
      color: rgb(170, 170, 170);
    }
  }
}
</style>
